:root {
    --orange: #FFEABA;
    --active: #BACFFF;
    --nonActive: #FFBAF2;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

button, span, label, p, h1, li {
    font-family: 'Roboto', sans-serif;
}

button,span,label {
    font-size: 1.25rem;
}

h1 {
    font-size: 2rem;
}

p, li {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

#separator {
    font-size: 1.75rem;
}

.sortingUI {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.navBar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5vh;
    width: 100vw;
    background-color: var(--orange);
    padding: 5px;
}

button {
    border: none;
    margin: 5px;
    background-color: transparent;
}

button:hover {
    color: var(--active);
}

.active {
    color: var(--active);
    font-weight: bold;
}

.nonActive {
    color: var(--nonActive);
}

.nonActive:hover {
    color: var(--nonActive);
}

.bar {
    display: inline-block;
    width: 3px;
    margin: 0 1px;
    background-color: #ECD6A2;
}

#slider {
    display: flex;
    justify-content: center;
    align-items: center;
}

#slider label {
    margin: 5px;
}

#slider input[type=range] {
    -webkit-appearance: none;
    width: 100%;
}

#slider input[type=range]::-webkit-slider-runnable-track {
    background: var(--active);
    height: 14px;
}
#slider input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 14px;
    width: 10px;
    background: black;
    cursor: pointer;
}

.show {
    max-width: 500px;
}

.noShow {
    display: none;
}